<template>
  <div>
    <Input
      class="subtitle"
      v-model="hb.contentTitle[currentIndex].content"
      :disabled="contentLocked"
      placeholder="请输入文章标题"
    />
    <Input
      style="width: 8em; margin: 0 2.75em"
      v-model="hb.attributeList[currentIndex].dynasty"
      v-if="'cnIntensivePoetry' === hb.category"
      :disabled="contentLocked"
      placeholder="朝代"
    />
    <Input
      style="width: 8em"
      v-model="hb.attributeList[currentIndex].author"
      v-if="'cnIntensivePoetry' === hb.category"
      :disabled="contentLocked"
      placeholder="作者"
    />
    <div style="margin-bottom: 1em"></div>
    <template v-if="!contentLocked">
      <Button
        style="margin-right: 1em"
        v-for="cellTp in cellTypes"
        :key="cellTp.chapterCode"
        @click="addCell(cellTp)"
        >{{ cellTp.content }}</Button
      >
    </template>
    <div class="flex" style="margin-top: 1em">
      <EditorSider
        :activeIndex.sync="currentIndex"
        :len="hb.mdContentList.length"
        :isEmptyFn="isContentEmpty"
        :modifiable="!contentLocked"
        @clickAdd="addContent"
        @clickRemove="removeContent"
      />

      <div v-if="onlyVideoUpload" class="flex1 m-l-sm">
        <ContentCell
          v-for="(cell, i) in cells.filter((ele) => ele.divStyle === 'video')"
          :key="cell.id"
          :info="cell"
          :videoUploadAble="true"
          :editable="!contentLocked"
          @del="cells.splice(i, 1)"
        />
      </div>
      <transition-group class="flex1 m-l-sm" tag="div" name="slide" v-else>
        <ContentCell
          v-for="(cell, i) in cells"
          :key="cell.id"
          :info="cell"
          :editable="!contentLocked"
          @del="cells.splice(i, 1)"
        />
      </transition-group>
    </div>
    <div class="flex" style="align-items: center" v-if="showImgUploader">
      <div>文章标题图片：</div>
      <ImgSelect
        :imgHolders="[hb.contentTitle[currentIndex]]"
        :config="{ hiddeSelectWhenFull: true, file: 'file' }"
      />
      <div style="width: 280px"></div>
    </div>
    <div v-if="showImgUploader">
      <ImgSelect
        v-for="(cnt, ind) in hb.mdContentList"
        :key="ind"
        v-show="currentIndex === ind"
        :imgHolders="
          hb.attributeList[ind].contentDivList.filter(
            (ele) => ele.divStyle === 'image'
          )
        "
        :config="{ hiddeSelectWhenFull: true, file: 'file' }"
      />
    </div>
  </div>
</template>

<script>
import ContentCell from "./ContentCell";
import { getContentExt } from "./intensiveApi";
import hbMixin from "../hbMixin";
export default {
  components: { ContentCell },
  props: {
    hb: {},
    contentLocked: {},
    showImgUploader: {
      default: false,
    },
    onlyVideoUpload: {
      default: false,
    },
  },
  mixins: [hbMixin],
  data() {
    return {
      cellTypes: [],
    };
  },
  beforeMount() {
    this.currentIndex = 0;
  },
  computed: {
    cells() {
      let info = this.hb.attributeList[this.currentIndex];
      if (info) {
        for (let item of info.contentDivList) {
          if (!item.id) {
            this.$set(item, "id", Math.random().toString());
          }
        }
        return info.contentDivList;
      }
      return [];
    },
  },

  methods: {
    isContentEmpty() {
      return this.cells.length <= 0;
    },
    addCell(cellType) {
      this.cells.push({
        divStyle: cellType.divStyle,
        chapterCode: cellType.chapterCode,
        content: "",
        titleName: cellType.content,
        key: "",
        url: "",
      });
    },
    onCategoryChange() {
      getContentExt(this.hb.category).then((res) => {
        if (this.cellTypes.length) {
          this.hb.attributeList.forEach((info) => {
            info.contentDivList.splice(0);
          });
          this.cellTypes = res;
          return;
        }
        this.cellTypes = res;
        this.hb.attributeList.forEach((info) => {
          info.contentDivList.forEach((cell) => {
            if (cell.titleName) {
              return;
            }
            let type = res.find(
              ({ chapterCode }) => chapterCode === cell.chapterCode
            );
            this.$set(cell, "titleName", type ? type.content : "");
          });
        });
      });
    },
  },
  watch: {
    "hb.category": {
      handler() {
        if (!this.hb.category) {
          return;
        }
        this.onCategoryChange();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.flex1 {
  flex: 1;
}
.subtitle {
  width: calc(50% - 3em);
}
/* .slide-move{
  transition: transform 1s;
}
.slide-enter,.slide-leave-to{
  height: 0;
}
.slide-enter-active,.slide-leave-active{
  transition: all 1s;
} */
</style>