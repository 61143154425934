<template>
  <div class="flex-middle" style="padding:0 0 1em">
    <div class="flex1">
      <span>{{info.titleName}}</span>
      <Input
        type="textarea"
        :disabled="!editable"
        :rows="5"
        v-model="info.content"
        v-if="info.divStyle==='txt'"
        class="item"
      />
      <div v-else-if="info.divStyle==='image'" class="item" style="text-align:center;">
        <img :src="info.url||'/images/img-mark.jpg'" style="max-width:100%;max-height:215px" />
      </div>
      <template v-else-if="info.divStyle==='video'">
        <video v-if="info.url" :src="info.url" controls style="max-width:100%;max-height:100%" />
        <div v-else-if="videoUploadAble" class="item" style="text-align:center;">
          <Upload action="noAction" accept="video/*" :before-upload="proxyUpload">
            <span class="upload-video">
              <img src="/images/video-ico.svg" />
              <br />视频标记
            </span>
          </Upload>
        </div>
        <div v-else class="item" style="text-align:center;">
          <img src="/images/video-ico.svg" />
          <br />视频标记
        </div>
      </template>
    </div>
    <div style="width:4em;text-align:center">
      <Button type="text" @click="$emit('del')" v-if="editable">
        <Icon class="text-error" style="font-size:24px" type="md-remove-circle" />
      </Button>
      <Button v-else-if="videoUploadAble&&info.url" type="text" @click="delVideo">
        <Icon class="text-error" style="font-size:24px" type="md-remove-circle" />
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {},
    editable: {
      default: true
    },
    videoUploadAble: {
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    proxyUpload(file) {
      if (file.size > 50 << 20) {
        this.$Message.warning("视频大小不能超过50M");
        return false;
      }
      this.info.file = file;
      this.info.url = URL.createObjectURL(file);
      return false;
    },
    delVideo() {
      this.info.url = "";
      this.info.file = null;
    }
  }
};
</script>

<style scoped>
.item {
  margin-top: 0.5em;
  margin-bottom: 1em;
  min-height: 115px;
}
.card {
  border-radius: 0.25em;
  padding: 0.5em;
  border: 1px solid #ddd !important;
}

.upload-video {
  cursor: pointer;
}
</style>